// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

// Store
import Store from '@/Store/Global/Default'

// Parser Exports
export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea',
		'name', 'pLastName', 'mLastName', 'storages', 'roleName', 'workAreaName', 'permissions'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').ignoreSpecialChars().isSortable().setAlign('center', 'center')
		.add('mName', 'Nombre').isSortable().setSearchKey('user.name')
		.add('email', 'Correo').isSortable()
		.add('phone', 'Fono').isSortable()
		.add('companyName', 'Cliente').isSortable().setSearchKey('company.name')
		.add('isValid', '¿Esta Activo?').setAlign('center', 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'mName', 'email', 'phone', 'companyName', 'storagesName', 'isValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('companyName')
		.add('mName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mName: (v: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		phone: (values: Array<string> | string) => {
			if (Array.isArray(values)) return PrimitiveTools.Arrays.isInvalidOrEmpty(values) ? AppValues.Strings.DEFAULT_EMPTY_STRING : values
			if (typeof values === 'string') if (values !== '') return [values]
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea',
		'email', 'name', 'pLastName', 'phone', 'mLastName', 'role', 'workarea', 'permissions', 'companyName', 'storages', 'isValid'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').ignoreSpecialChars().isSortable().setAlign('center', 'center').showDetailsButton()
		.add('mName', 'Nombre').isSortable().setSearchKey('searchUser')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'mName'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('mName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mName: (_: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Correo']: ({ email }) => {
			return email
		},
		['Fono']: ({ phone }) => {
			return PrimitiveTools.Arrays.isInvalidOrEmpty(phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : phone
		},
		// ['Ubicaciones']: ({ storages }) => {
		// 	if (!Array.isArray(storages) || storages.length === 0) return AppValues.Strings.DEFAULT_EMPTY_STRING
		// 	return storages.map((x) => x.name)
		// },
		['Cliente']: ({ companyName }) => {
			return companyName
		},
		['¿Esta Activo?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}